import { createSelector } from 'reselect';
import { calculateInterval } from '../../../../utils/calculateInterval';
import { isDESMA } from '../../../../utils/desma';
import isValidYAxisConfig from './isValidYAxisConfig';

export const searchSelector = (state) => ({
  ...state.search,
  timezone: state.auth.user.timezone,
});

export const machineSelector = (state) => state.machine || {};
export const configSelector = (state, { widget: { config } }) => config;
const desmaWidgetsSelector = (state) => isDESMA(state.config);

export function findDatasource(stateMachine, datasourceId) {
  let datasource;
  if (!stateMachine) {
    // eslint-disable-next-line no-param-reassign
    stateMachine = {};
  }
  Object.keys(stateMachine).forEach((mid) => {
    if (!datasource) {
      if (!stateMachine[mid].datasources || !stateMachine[mid].datasources.length) {
        return;
      }
      datasource = stateMachine[mid].datasources.find((ds) => ds.datasource_id === datasourceId);
    }
  });
  return datasource;
}

export function findMachineSchema(state_machine, datasource_id) {
  if (!state_machine) {
    // eslint-disable-next-line no-param-reassign
    state_machine = {};
  }
  let schema = null;
  if (state_machine) {
    Object.keys(state_machine).forEach((mid) => {
      if (!state_machine[mid].datasources || !state_machine[mid].datasources.length) {
        return schema;
      }
      state_machine[mid].datasources.forEach((ds) => {
        if (ds.datasource_id === datasource_id) {
          if (ds.function === 'data') {
            schema = state_machine[mid].schema;
          } else {
            schema = ds.document_schema;
          }
        }
      });
      return schema;
    });
  }

  return schema;
}

export function getDevicesByDatasources(state_devices, config_datasources) {
  if (!config_datasources || !config_datasources.length) {
    return null;
  }
  const devices = [];
  config_datasources.forEach((ds) => {
    if (ds && ds.datasource) {
      const device = findMachineByDatasource(state_devices, ds.datasource);
      if (device) {
        devices.push(device);
      }
    }
  });
  return devices;
}

export function getDevicesByEntityID(devices, entity_id) {
  if (devices && entity_id) {
    return Object.values(devices)
      .filter((d) => d.entity_id === entity_id)
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  return [];
}

export function findMachineByDatasource(state_machine, datasource_id) {
  let machine;
  if (!datasource_id) {
    return machine;
  }
  if (!state_machine) {
    // eslint-disable-next-line no-param-reassign
    state_machine = {};
  }
  Object.keys(state_machine).forEach((mid) => {
    if (!machine) {
      if (!state_machine[mid].datasources || !state_machine[mid].datasources.length) {
        return;
      }

      state_machine[mid].datasources.forEach((ds) => {
        if (ds.datasource_id === datasource_id) {
          machine = state_machine[mid];
        }
      });
    }
  });

  return machine;
}

// function findBestDatasourceOrNull(stateMachine, datasourceFunctionPreference) {
//   let datasource;
//   Object.keys(stateMachine).forEach(mid => {
//     if (!datasource) {
//       if (!stateMachine[mid].datasources || !stateMachine[mid].datasources.length) {
//         return;
//       }
//       datasource = stateMachine[mid].datasources.find(
//         d => d.function === datasourceFunctionPreference
//       );
//     }
//   });
//   return datasource;
// }

export function getFilterQuery(
  search,
  ignore_time = false,
  filter_field = undefined,
  timezone = undefined,
  custom_time_from = undefined,
  custom_time_to = undefined
) {
  if (search.filter && search.filter.length > 0) {
    const arr = [];
    // eslint-disable-next-line no-unused-vars
    const sf = search.filter.forEach((f, k) => {
      if (f.field && f.field.value && f.field_value) {
        arr[k] = {
          wildcard: {
            [String(f.field.value)]: f.field_value,
          },
        };
      }
    });

    if (arr.length === 0) {
      return getTimestampRangeQuery(
        search,
        ignore_time,
        filter_field,
        timezone,
        custom_time_from,
        custom_time_to
      );
    }
    return {
      bool: {
        must: [
          {
            bool: {
              should: [arr],
            },
          },
          {
            bool: {
              must: getTimestampRangeQuery(
                search,
                ignore_time,
                filter_field,
                timezone,
                custom_time_from,
                custom_time_to
              ),
            },
          },
        ],
      },
    };
  }

  // if (search.filter && search.filter.length === 1 && search.filter[0].field && search.filter[0].field_value) {
  //   return {
  //     bool: {
  //       must: getTimestampRangeQuery(search, ignore_time),
  //       filter: {
  //         term: {
  //           [search.filter[0].field.value]: search.filter[0].field_value,
  //         },
  //       },
  //     },
  //   }
  // }

  return getTimestampRangeQuery(
    search,
    ignore_time,
    filter_field,
    timezone,
    custom_time_from,
    custom_time_to
  ); // here
}

export const datasourceSelector = (state, props) => {
  const config = configSelector(state, props);
  const dashboard_config = state.search;
  if (
    config &&
    config.dashboard_filter &&
    dashboard_config &&
    dashboard_config.filter_machine &&
    dashboard_config.filter_machine.length &&
    dashboard_config.filter_datasource &&
    dashboard_config.filter_datasource.length
  ) {
    return findDatasource(dashboard_config.filter_machine, dashboard_config.filter_datasource);
  }
  if (config && config.datasource_id) {
    return findDatasource(state.machine, config.datasource_id);
  }
  if (config && config.datasource) {
    return findDatasource(state.machine, config.datasource);
  }
  // datasourceFunctionPreference;
  return null;
  // const fb = findBestDatasourceOrNull(state.machine, datasourceFunctionPreference);
  // console.log('findBestDatasourceOrNull', config, fb);
  // return fb;
};

export const multiDatasourcesSelector = (state, props) => {
  const config = configSelector(state, props);
  if (config && config.datasources) {
    return config.datasources.map((v) =>
      v ? findDatasource(state.machine, v.datasource) : undefined
    );
  }
  return null;
};

export const allDatasourcesSelector = (state, props) => {
  if (props.machines && props.machines.length) {
    const result = [];
    props.machines.forEach((machine) =>
      machine.datasources.forEach((ds) =>
        result.push(findDatasource(state.machine, ds.datasource_id))
      )
    );
    return result;
  }
  return null;
};

// function getTimezoneOffset() {
//   function z(n) {
//     return (n < 10 ? '0' : '') + n;
//   }
//   let offset = new Date().getTimezoneOffset();
//   const sign = offset < 0 ? '+' : '-';
//   offset = Math.abs(offset);
//   // eslint-disable-next-line no-bitwise
//   return sign + z((offset / 60) | 0) + z(offset % 60);
// }

// export function getTimezoneString(timezone) {
//   if (!timezone) {
//     // use browser timezone as fallback

//     // eslint-disable-next-line no-param-reassign
//     timezone = getTimezoneOffset();
//   }
//   // eslint-disable-next-line no-param-reassign
//   timezone = String(timezone);

//   // Append ":" between the timezone offset
//   const formatedTimezone = timezone.slice(0, 3).concat(':').concat(timezone.slice(3));

//   return formatedTimezone;
// }

export function getOffsetTimestampRangeQuery(search, offset) {
  const dFrom = new Date(search.from);
  const validDateFrom = dFrom && dFrom.getTime && !isNaN(dFrom.getTime());

  // wenn der "FROM" timestamp kein date-field ist und kein ganzer tag/woche/monats/jahres-wert hat
  // dann kein "must_not" query anwenden
  if (
    !validDateFrom &&
    !search.from.includes('now/d') &&
    !search.from.includes('now/w') &&
    !search.from.includes('now/M') &&
    !search.from.includes('now/y')
  ) {
    return null;
  }

  let fromString = search.from;
  if (validDateFrom) {
    dFrom.setHours(dFrom.getHours() + offset);
    // wenn der "FROM" timestamp inkl. offset in der zukunft liegt, dann kein "must_not" query anwenden
    if (dFrom.getTime() >= Date.now()) {
      return null;
    }
    fromString = dFrom;
  } else {
    fromString = `${search.from}+${offset}h`;
  }

  return {
    range: {
      '@timestamp': { lt: fromString, time_zone: search.timezone },
    },
  };
}

export function getTableQuery(
  fields,
  search,
  ignore_time = false,
  filter_field = undefined,
  timezone = undefined,
  custom_time_from = undefined,
  custom_time_to = undefined
) {
  return {
    bool: {
      must: [
        getTimestampRangeQuery(
          search,
          ignore_time,
          filter_field,
          timezone,
          custom_time_from,
          custom_time_to
        ),
        {
          bool: {
            should: fields.map((f) => ({
              bool: {
                must: {
                  exists: {
                    field: String(f),
                  },
                },
              },
            })),
          },
        },
      ],
    },
  };
}

export function getTimestampRangeQuery(
  search,
  ignore_time = false,
  filter_field = undefined,
  timezone = undefined,
  custom_time_from = undefined,
  custom_time_to = undefined
) {
  const searchFrom = custom_time_from || search.from;
  const searchTo = custom_time_to || search.to;

  if (ignore_time === true && !filter_field) {
    return {
      match_all: {},
    };
  }
  if (filter_field) {
    if (ignore_time === true) {
      return {
        bool: {
          must: [
            {
              match_all: {},
            },
            {
              exists: {
                field: String(filter_field),
              },
            },
          ],
        },
      };
    }
    return {
      bool: {
        must: [
          {
            range: {
              '@timestamp': {
                gte: searchFrom,
                lt: searchTo,
                time_zone: timezone,
              },
            },
          },
          {
            exists: {
              field: String(filter_field),
            },
          },
        ],
      },
    };
  }

  return {
    range: {
      '@timestamp': {
        gte: searchFrom,
        lt: searchTo,
        time_zone: timezone,
      },
    },
  };
}

// used by MachineAlertsDurationWidget
export function getAlarmRangeQuery(search, timezone, custom_time_from, custom_time_to) {
  return {
    bool: {
      must: [
        {
          range: {
            startDate: {
              lte: custom_time_to || search.to,
              time_zone: timezone,
            },
          },
        },
        {
          range: {
            endDate: {
              gte: custom_time_from || search.from,
              time_zone: timezone,
            },
          },
        },
      ],
    },
  };
}

export const aggregationPieChartSelector = createSelector(
  searchSelector,
  configSelector,
  desmaWidgetsSelector,
  (search, config, is_desma) => {
    const { attributes } = config;
    const timezone = search.timezone;
    const aggs = {};

    if (!attributes) {
      return null;
    }

    attributes.forEach((att, index) => {
      aggs[index] = {
        [att.aggType]: { field: String(att.property) },
      };
    });

    if (is_desma === true) {
      return {
        size: 0,
        query: getTimestampRangeQuery(
          search,
          undefined,
          undefined,
          search.timezone,
          config.custom_time_from,
          config.custom_time_to
        ),
        aggs: {
          piechartdata: {
            date_histogram: {
              interval: 'year',
              field: '@timestamp',
              time_zone: timezone,
            },
            aggs,
          },
        },
      };
    }
    return {
      size: 0,
      query: getTimestampRangeQuery(
        search,
        undefined,
        undefined,
        search.timezone,
        config.custom_time_from,
        config.custom_time_to
      ),
      aggs: {
        piechartdata: {
          auto_date_histogram: {
            field: '@timestamp',
            time_zone: timezone,
            buckets: 1,
          },
          aggs,
        },
      },
    };
  }
);

export const aggregationQuerySelectorMulti = (search, config, yAxis, time_offset) => {
  const {
    xAxis,
    valuesCount,
    valuesInterval,
    aggregationInterval,
    hide_zero_values,
    custom_time_from,
    custom_time_to,
  } = config;
  if (!xAxis || !yAxis || !yAxis.length) {
    return null;
  }
  let agg;

  const timezone = search.timezone;
  const searchFrom = custom_time_from || search.from;
  const searchTo = custom_time_to || search.to;

  if (xAxis === '@timestamp') {
    if (config && config.ignore_time && config.ignore_time === true) {
      // this is the case if the filter is used
      agg = {
        date_histogram: {
          field: '@timestamp',
          interval:
            // eslint-disable-next-line no-nested-ternary
            valuesInterval && valuesInterval.length
              ? valuesInterval
              : aggregationInterval && aggregationInterval.length
              ? aggregationInterval
              : 'hour',
          time_zone: timezone,
          min_doc_count: 1, // prevents freeze if time is ignored
          offset: time_offset ? `${time_offset}h` : undefined,
        },
      };
    } else {
      agg = {
        date_histogram: {
          field: '@timestamp',
          interval:
            // eslint-disable-next-line no-nested-ternary
            valuesInterval && valuesInterval.length
              ? valuesInterval
              : aggregationInterval && aggregationInterval.length
              ? aggregationInterval
              : calculateInterval(searchFrom, searchTo, valuesCount),
          time_zone: timezone,
          min_doc_count: hide_zero_values === true ? 1 : undefined,
          extended_bounds: {
            min: searchFrom,
            max: searchTo,
          },
          offset: time_offset ? `${time_offset}h` : undefined,
        },
      };
    }
  } else {
    agg = {
      terms: {
        field: String(xAxis),
        min_doc_count: hide_zero_values === true ? 1 : undefined,
        size: valuesCount,
        order: { _key: 'asc' },
      },
    };
  }

  const aggs = {};
  yAxis.filter(isValidYAxisConfig).forEach((axisConfig, index) => {
    const fieldProperty = String(axisConfig.property);
    let aggType = 'max';
    if (axisConfig.aggType) {
      aggType = axisConfig.aggType;
    }
    aggs[index] = {
      [aggType]: { field: fieldProperty },
    };
  });

  if (!aggs || !aggs[0]) {
    return null;
  }

  if (time_offset) {
    return {
      size: 0,
      query: {
        bool: {
          must: getTimestampRangeQuery(
            search,
            undefined,
            undefined,
            search.timezone,
            custom_time_from,
            custom_time_to
          ),
          must_not: getOffsetTimestampRangeQuery(search, time_offset),
        },
      },
      aggs: {
        chartdata: {
          ...agg,
          aggs,
        },
      },
    };
  }

  return {
    size: 0,
    query:
      config.dashboard_filter && config.dashboard_filter === true
        ? getFilterQuery(
            search,
            config.ignore_time,
            undefined,
            search.timezone,
            custom_time_from,
            custom_time_to
          )
        : getTimestampRangeQuery(
            search,
            config.ignore_time,
            undefined,
            search.timezone,
            custom_time_from,
            custom_time_to
          ),
    aggs: {
      chartdata: {
        ...agg,
        aggs,
      },
    },
  };
};

export const heatmapQuerySelector = createSelector(
  searchSelector,
  configSelector,
  // machineSelector,
  (search, config) => {
    const { property, agg_type = 'max' } = config;
    if (property == null) {
      return null;
    }

    // const datasource = findDatasource(machine, config.datasource_id);

    const timezone = search.timezone;
    // const searchFrom = search.from;
    // const searchTo = search.to;

    const agg = {
      date_histogram: {
        field: '@timestamp',
        interval: 'hour',
        time_zone: timezone,
        // extended_bounds: {
        //   min: searchFrom,
        //   max: searchTo,
        // },
      },
    };

    const aggs = {
      [String(property)]: {
        [agg_type]: { field: String(property) },
      },
    };

    // aggs[0] = {
    //   max: { field: String(property) },
    // };

    // if (!aggs || !aggs[0]) {
    //   return null;
    // }

    return {
      size: 0,
      query: getTimestampRangeQuery(search, false, undefined, timezone),
      aggs: {
        chartdata: {
          ...agg,
          aggs,
        },
      },
    };
  }
);

export const aggregationQuerySelector = createSelector(
  searchSelector,
  configSelector,
  machineSelector,
  (search, config, machine) => {
    const {
      xAxis,
      yAxis,
      valuesCount,
      valuesInterval,
      aggregationInterval,
      hide_zero_values,
      custom_time_from,
      custom_time_to,
    } = config;
    if (!xAxis || !yAxis || !yAxis.length) {
      return null;
    }
    let agg;

    const timezone = search.timezone;
    const searchFrom = custom_time_from || search.from;
    const searchTo = custom_time_to || search.to;

    if (xAxis === '@timestamp') {
      if (config && config.ignore_time && config.ignore_time === true) {
        // this is the case if the filter is used
        agg = {
          date_histogram: {
            field: '@timestamp',
            interval:
              // eslint-disable-next-line no-nested-ternary
              valuesInterval && valuesInterval.length
                ? valuesInterval
                : aggregationInterval && aggregationInterval.length
                ? aggregationInterval
                : 'hour',
            time_zone: timezone,
            min_doc_count: hide_zero_values === true ? 1 : undefined,
          },
        };
      } else {
        agg = {
          date_histogram: {
            field: '@timestamp',
            interval:
              aggregationInterval && aggregationInterval.length
                ? aggregationInterval
                : calculateInterval(searchFrom, searchTo, valuesCount),
            time_zone: timezone,
            min_doc_count: hide_zero_values === true ? 1 : undefined,
            extended_bounds: {
              min: searchFrom,
              max: searchTo,
            },
          },
        };
      }
    } else {
      agg = {
        terms: {
          field: String(xAxis),
          min_doc_count: hide_zero_values === true ? 1 : undefined,
          size: valuesCount,
          order: { _key: 'asc' },
        },
      };
    }

    const aggs = {};

    yAxis.filter(isValidYAxisConfig).forEach((axisConfig, index) => {
      const fieldProperty = String(axisConfig.property);

      if (aggregationInterval === 'hour') {
        aggs[index] = {
          max: { field: fieldProperty },
        };
      } else if (aggregationInterval === 'day') {
        aggs[index] = {
          sum: { field: fieldProperty },
        };
      } else if (aggregationInterval === 'week') {
        aggs[index] = {
          sum: { field: fieldProperty },
        };
      }
      aggs[index] = {
        [axisConfig.aggType]: { field: fieldProperty },
      };
    });
    if (!aggs || !aggs[0]) {
      return null;
    }

    return {
      size: 0,
      query:
        config.dashboard_filter && config.dashboard_filter === true
          ? getFilterQuery(
              search,
              config.ignore_time,
              undefined,
              search.timezone,
              custom_time_from,
              custom_time_to
            )
          : getTimestampRangeQuery(
              search,
              config.ignore_time,
              undefined,
              search.timezone,
              custom_time_from,
              custom_time_to
            ),
      aggs: {
        chartdata: {
          ...agg,
          aggs,
        },
      },
    };
  }
);

// function getAggs(name, lst) {
//   const aggs = {};
//   lst.forEach(l => {
//     if (l && l.property) {
//       aggs[`${name}-${l.property}`] = {
//         [l.aggType || 'max']: { field: l.property },
//       };
//     }
//   });
//   return aggs;
// }

// used by OEEWidget
function getAllowedAggregationName(propertyName) {
  return propertyName.replace('[', 'LENTICULARBRACKETOPEN').replace(']', 'LENTICULARBRACKETCLOSE');
}

// used by OEEWidget
export function getValueFromResultLastHit(result, propertyName) {
  const aggName = getAllowedAggregationName(propertyName);
  if (!result || !result.aggregations || !result.aggregations[aggName]) {
    return null;
  }
  const hits = result.aggregations[aggName].hits.hits;
  if (!hits.length) {
    return null;
  }
  return hits[0]._source[propertyName];
}

// used by OEEWidget
function getLastHitAggregation(propertyName) {
  return {
    top_hits: {
      sort: [
        {
          _script: {
            type: 'number',
            script: {
              lang: 'painless',
              inline: `doc['${propertyName}'].value == 0 ? 0L : doc['@timestamp'].value.millis`,
            },
            order: 'desc',
          },
        },
      ],
      size: 1,
      _source: ['@timestamp', propertyName],
    },
  };
}

// not used
export function getLastHitAggregationForFieldName(fieldName) {
  return {
    [getAllowedAggregationName(fieldName)]: {
      top_hits: {
        sort: [{ '@timestamp': 'desc' }],
        size: 1,
        _source: ['@timestamp', fieldName],
      },
    },
  };
}

// used by OEEWidget
export function getLastHitAggregationsForFields(fields) {
  return fields.reduce(
    (aggs, field) =>
      field && field.property
        ? {
            ...aggs,
            [getAllowedAggregationName(field.property)]: getLastHitAggregation(field.property),
          }
        : aggs,
    {}
  );
}

// used by OEEWidget
export const buildLastHitQuerySelector = (state, props) => {
  const search = state.search;
  const config = props.widget.config;
  const timezone = state.auth.user.timezone;

  // const aggs = {
  //   ...getAggs('availabilityLosses', config.availabilityLosses),
  //   ...getAggs('productionLosses', config.productionLosses),
  //   ...getAggs('qualityLosses', config.qualityLosses),
  // };
  //   availabilityLosses: {
  //     sum: {
  //       aggs: getAggs(config.availabilityLosses),
  //     },
  //   },
  //   productionLosses: {
  //     sum: {
  //       aggs: getAggs(config.productionLosses),
  //     },
  //   },
  //   qualityLosses: {
  //     sum: {
  //       aggs: getAggs(config.qualityLosses),
  //     },
  //   },
  // };

  return {
    size: 0,
    query: {
      range: { '@timestamp': { lte: search.to, time_zone: timezone } },
    },
    aggs: {
      ...getLastHitAggregationsForFields(config.availabilityLosses),
      ...getLastHitAggregationsForFields(config.productionLosses),
      ...getLastHitAggregationsForFields(config.qualityLosses),
    },
  };
};

export function findDeviceByDatasource(stateMachine, datasourceId) {
  let device_id;
  Object.keys(stateMachine).forEach((mid) => {
    if (!device_id) {
      if (!stateMachine[mid].datasources || !stateMachine[mid].datasources.length) {
        return;
      }
      const datasource = stateMachine[mid].datasources.find(
        (ds) => ds.datasource_id === datasourceId
      );
      if (datasource) {
        device_id = mid;
      }
    }
  });
  return device_id;
}
